.wh-10 {
    width: 10px;
    height: 10px;
}

.wh-13 {
    width: 13px;
    height: 13px;
}

.wh-15 {
    width: 15px;
    height: 15px;
}

.wh-20 {
    width: 20px;
    height: 20px;
}

.wh-25 {
    width: 25px;
    height: 25px;
}

.wh-30 {
    width: 30px;
    height: 30px;
}

.wh-35 {
    width: 35px;
    height: 35px;
}

.wh-40 {
    width: 40px;
    height: 40px;
}

.wh-45 {
    width: 45px;
    height: 45px;
}

.wh-50 {
    width: 50px;
    height: 50px;
}

.wh-70 {
    width: 70px;
    height: 70px;
}

.wh-100 {
    width: 100px;
    height: 100px;
}