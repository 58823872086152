.nav-bar {
  display: flex;
  align-items: center;
  position: fixed;
  margin-top: 50px;
  height: 40px;
  padding: 0px 5px;
  left: 0;
  right: 0;
  font-weight: 500;
  list-style: none;
  background-color: #ffffff;
  border-bottom: solid 1px #dbe6e9;
  z-index: 900;
}

.nav-bar .nav-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #b9edff;
  color: #184354;
  text-decoration: none;
  margin: 0 4px;
  padding: 2px 6px;
  font-size: 14px;
  border-radius: 4px;
  transition: all 0.3s;
}

.nav-bar .icon {
  width: 15px;
  height: 15px;
}

.nav-bar .nav-item.collapsed {
  background-color: #eef3f7;
  color: #415f6b;
}

.nav-bar .nav-item:hover {
  background-color: #d1e4ed;
}