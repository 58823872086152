.table-wrapper {
    width: 100%;
    background-color: white;
    overflow-x: auto;
    padding: 15px 0;
}

.table-container {
    width: 1123px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 12px;
    background-color: white;
    padding: 15px;
    box-sizing: border-box;
}

.table-classic {
    width: 100%;
    margin: 15px 0px;
    font-size: 11px;
    border-collapse: collapse;
}

.table-classic td, .table-classic th {
    border: 1px solid #121212;
    text-align: left;
    padding: 1px 3px;
}

.vertical-text {
    writing-mode: vertical-rl; /* Text flows from top to bottom */
    transform: rotate(180deg); /* Rotate the text if necessary */
    text-align: center; /* Center align the text horizontally */
}