.nav-bar-stock-products {
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  left: 260px;
  right: 0;
  padding: 10px 2px;
  background-color: #f1f5f6;
  z-index: 900;
  box-shadow: 2px 2px 5px #d9e0e4;
  transition: all 0.3s;
}

.nav-bar-stock-products .nav-list {
  position: fixed;
  display: flex;
  flex-direction: row;
}

.nav-bar-stock-products .nav-item {
  text-decoration: none;
  margin: 0px 2px;
}

.nav-bar-stock-products .nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  padding: 2px 7px;
  font-weight: 500;
  color: #1aa15e;
  border-bottom: 2px solid #1cd767;
  transition: all 0.1s;
}

.nav-bar-stock-products .nav-link.collapsed {
  color: #144964;
  font-weight: 400;
  border-bottom: none;
}

.nav-bar-stock-products .nav-link:hover {
  color: #35d001;
  margin-right: 0px;
}